import type { BlockLinkProps } from '@elseu/sdu-titan-content-rendering';

import type icons from './icons';

interface GetBlockLinkBaseConfiguration {
  id: string;
  title: string;
}

export const getBlockLinkBaseConfiguration = ({
  id,
  title: label,
}: GetBlockLinkBaseConfiguration): Pick<BlockLinkProps, 'id' | 'label' | 'icon'> => ({
  id,
  label,
  icon: 'ChevronRightIcon' as keyof typeof icons,
});
