import type { Urls } from '../context/SiteConfigProvider';

interface GetMenuPageLinksByTypename {
  __typename?: string;
  swsDocumentId?: string;
  href?: string;
}

const getMenuPageLinksByTypename = <T extends GetMenuPageLinksByTypename>(
  item: T,
  urls: Urls,
): string => {
  const { href, __typename, swsDocumentId } = item;
  if (__typename === 'MenuPageBlockSwsDocumentLink') {
    return `${urls.contentUrl}/${swsDocumentId}`;
  }

  return href || '/';
};

export { getMenuPageLinksByTypename };
