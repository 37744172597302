import { Spinner } from '@elseu/sdu-titan';

import { useContentRendering } from '../../context/ContentRenderingProvider';
import { usePnNavigationDrawerQuery } from '../../generated/content/graphql';
import { useContentRenderingUrls } from '../../hooks/useContentRenderingUrls';
import { ErrorComponent } from '../ErrorPage/ErrorComponent';
import { usePnNavigationLinks } from '../PnNavigation/PnNavigationDrawer/hooks/usePnNavigationLinks';
import type { BlockWithLinksProps } from './BlockWithLinks';
import { BlockWithLinks } from './BlockWithLinks';
import { BlockWrapper } from './BlockWrapper';

export type BlockWithPnNavigationLinksProps = Omit<BlockWithLinksProps, 'links'> & {
  documentKey: string;
  slug: string;
  subId?: string;
};

export const BlockWithPnNavigationLinks = ({
  documentKey,
  subId,
  isPrimary,
  slug,
  ...rest
}: BlockWithPnNavigationLinksProps) => {
  const { applicationKey, client } = useContentRendering();
  const { contentUrl: baseUrl } = useContentRenderingUrls();
  const {
    loading: isLoading,
    data,
    error,
  } = usePnNavigationDrawerQuery({
    variables: {
      documentKey,
      baseUrl,
      applicationKey,
      subId,
    },
    client,
  });

  const links = usePnNavigationLinks({
    data,
    documentKey,
    slug,
  });

  if (isLoading) {
    return (
      <BlockWrapper isPrimary={isPrimary}>
        <Spinner size={80} />
      </BlockWrapper>
    );
  }

  if (error) {
    return (
      <BlockWrapper isPrimary={isPrimary}>
        <ErrorComponent error={error} />
      </BlockWrapper>
    );
  }

  return <BlockWithLinks {...rest} isPrimary={isPrimary} links={links} />;
};
