const camelcase = (str: string) =>
  str
    .trim()
    .split(' ')
    .map((a) => a.replace(/[^a-zA-Z0-9]+/g, '').trim())
    .map((word, i) =>
      !i ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.substr(1).toLowerCase(),
    )
    .join('');

export { camelcase };
