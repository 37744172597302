import { ContentWrapper, Header } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { NextSeo } from 'next-seo';
import { arrayToTree } from 'performant-array-to-tree';
import type * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import {
  MenuPageDocument,
  type MenuPageQuery,
  type MenuPageQueryVariables,
} from '../../generated/cms/graphql';
import { camelcase } from '../../helpers/camelcase';
import { isNotEmpty } from '../../helpers/isNotEmpty';
import { useCMSQuery } from '../../hooks/useCmsQuery';
import { NotFound } from '../Error/NotFound/NotFound';
import { PageLoader } from '../PageLoader/PageLoader';
import { MenuPageGrid } from './MenuPageGrid';
import type { MenuPageColumnWithChildren } from './types';
interface MenuPageProps {
  slug: string;
}

const MasonryContainer = styled.div`
  position: relative;

  .xmasonry-static {
    text-align: center;
    overflow: auto;
  }

  .xblock-static {
    float: left;
  }

  .xblock {
    max-width: 100%;
    text-align: left;
  }
`;

const MenuPage: React.FC<MenuPageProps> = ({ slug }) => {
  const { response, isLoading } = useCMSQuery<MenuPageQuery, MenuPageQueryVariables>(
    MenuPageDocument,
    {
      variables: {
        slug: `/${slug}`,
      },
    },
  );

  const data = useMemo(() => response?.site?.menuPage, [response]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!data?.children?.length) {
    return <NotFound />;
  }

  const { title, children } = data;
  const filteredChildren = children.filter(isNotEmpty);
  const childrenTree = arrayToTree(filteredChildren, {
    rootParentIds: { '0': true },
    parentId: 'parent',
    dataField: null,
  }) as MenuPageColumnWithChildren[];

  const primaryItems = childrenTree.filter(
    (child) => child.__typename === 'MenuPageColumnType' && child.variant === 'primary',
  );
  const secondaryItems = childrenTree.filter(
    (child) => child.__typename === 'MenuPageColumnType' && child.variant !== 'primary',
  );
  const items = [...primaryItems, ...secondaryItems];

  return (
    <>
      <NextSeo title={title} />
      <ContentWrapper maxWidth={800}>
        <Header
          backLink="/"
          hasContentWrapper={false}
          labelBackLink={<Trans>Home</Trans>}
          testId={`${camelcase(title)}`}
          title={title}
          variant="primary"
        />
        <MasonryContainer>
          <MenuPageGrid items={items} slug={slug} />
        </MasonryContainer>
      </ContentWrapper>
    </>
  );
};

export { MenuPage };
