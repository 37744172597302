import type { PnNavigationDrawerItemFragment } from '../../../generated/content/graphql';
import type { BlockLinkProps } from '../BlockLink';

/**
 * Will return a curried function that will create a BlockLinkProps based on the type of the item
 */
interface CreateLinkProps {
  // base of the content-page urls
  contentUrl: string;
  // base of the practice notes navigation subpage url
  pnNavigationUrl: string;
  // the slug, to be replaced in the pnNavigationUrl
  slug: string;
  // the documentKey to redirect to
  documentKey: string;
}
export const createLink =
  ({ contentUrl, documentKey }: CreateLinkProps) =>
  (item: PnNavigationDrawerItemFragment): BlockLinkProps => {
    const { id, label, hasChildren } = item;

    const baseReturn = {
      id,
      label,
      icon: 'ChevronRightIcon' as BlockLinkProps['icon'],
    };

    if (item.__typename === 'DocumentTocEntry') {
      return {
        ...baseReturn,
        type: 'link',
        to: `${contentUrl}/${id}`,
      };
    }

    /**
     *  For now we turn off the subpage
     * @see https://jirasdu.atlassian.net/browse/THDC-3687
     * */
    //
    // if (depth >= 3) {
    //   return {
    //     ...baseReturn,
    //     type: 'link',
    //     to: getUrl(pnNavigationUrl, { slug, documentKey, subId: id }),
    //   };
    // }

    return {
      ...baseReturn,
      type: 'navigation',
      documentKey,
      subId: id,
      isInDevelopment: !hasChildren,
    };
  };
