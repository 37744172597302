import { ErrorBoundary } from '../components/Error/ErrorBoundary/ErrorBoundary';
import { ErrorBoundaryPageFallback } from '../components/Error/ErrorBoundaryPageFallback/ErrorBoundaryPageFallback';
import { MenuPage } from '../components/MenuPage/MenuPage';
import { useSearchParam } from '../hooks/useSearchParam';

const OverviewPage = () => {
  const slug = useSearchParam('slug') as string;
  return (
    <ErrorBoundary fallback={<ErrorBoundaryPageFallback />}>
      <MenuPage slug={slug} />
    </ErrorBoundary>
  );
};

export { OverviewPage };
