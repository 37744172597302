import { Col, FlexGrid, media, spacing } from '@elseu/sdu-titan';
import * as React from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { XBlock, XMasonry } from 'react-xmasonry';
import styled from 'styled-components';

import { MenuPageItem } from './MenuPageItem';
import type { MenuPageColumnWithChildren } from './types';

interface MenuPagePropsGrid {
  items: MenuPageColumnWithChildren[];
  slug: string;
}

enum GRID_TYPE {
  MASONRY = 'masonry',
  FLEX = 'flex',
}

const MasonryItemSpacer = styled.div`
  ${media.greaterThan('xs')`
    margin-right: ${spacing(8)};
  `}
`;

const MenuPageGrid = ({ items, slug }: MenuPagePropsGrid) => {
  const [viewState, setViewState] = React.useState<GRID_TYPE>(GRID_TYPE.FLEX);

  useIsomorphicLayoutEffect(() => {
    setViewState(GRID_TYPE.MASONRY);
  }, []);

  return viewState === GRID_TYPE.MASONRY ? (
    <XMasonry center={false} maxColumns={2} targetBlockWidth={400}>
      {items.map((item, index) => (
        <XBlock key={item.id}>
          <MasonryItemSpacer>
            <MenuPageItem index={index} item={item} slug={slug} />
          </MasonryItemSpacer>
        </XBlock>
      ))}
    </XMasonry>
  ) : (
    <FlexGrid gutter={{ xs: 0, m: 8 }}>
      {items.map((item, index) => (
        <Col key={item.id} width={{ xs: 1, s: 1 / 2 }}>
          <MenuPageItem index={index} item={item} slug={slug} />
        </Col>
      ))}
    </FlexGrid>
  );
};

export { MenuPageGrid };
