import type { Icon } from '@elseu/sdu-titan';
import {
  BookIcon,
  BoxAltIcon,
  CardsIcon,
  ChatAltIcon,
  ChatIcon,
  ChevronRightIcon,
  HelpIcon,
  HomeIcon,
  LibraryIcon,
  ListItem,
  ListLinkItem,
  MagazineIcon,
  NewsIcon,
  NtfrIcon,
  PeopleIcon,
  PersonIcon,
  StarOutlineIcon,
} from '@elseu/sdu-titan';

import { CommentaryLawTocDrawerToggle } from '../CommentaryLawToc/CommentaryLawToc/CommentaryLawTocDrawer/CommentaryLawTocDrawer';
import { PnNavigationDrawerToggle } from '../PnNavigation/PnNavigationDrawer/PnNavigationDrawer';

const icons = {
  BookIcon,
  ChevronRightIcon,
  BoxAltIcon,
  HelpIcon,
  HomeIcon,
  LibraryIcon,
  MagazineIcon,
  NewsIcon,
  NtfrIcon,
  PeopleIcon,
  PersonIcon,
  ChatIcon,
  ChatAltIcon,
  CardsIcon,
  StarOutlineIcon,
} as const;

type IconsType = keyof typeof icons;

interface BaseBlockLink {
  id: string;
  label: string;
  icon: IconsType;
}

interface Link extends BaseBlockLink {
  type: 'link';
  to: string;
}

interface Commentary extends BaseBlockLink {
  type: 'commentary';
  publicationId?: string;
  lawId: string;
}

interface Navigation extends BaseBlockLink {
  type: 'navigation';
  documentKey: string;
  subId: string;
  isInDevelopment?: boolean;
}

export type BlockLinkProps = Link | Commentary | Navigation;

export const BlockLink = (item: BlockLinkProps) => {
  const { icon, type, id, label } = item;
  const IconComponent = icons[icon] as Icon;

  if (type === 'commentary') {
    const { id, label, lawId, publicationId } = item;

    return (
      <CommentaryLawTocDrawerToggle
        key={`link-drawer-${id}`}
        button={
          <ListLinkItem
            prefixAdornment={<IconComponent />}
            testId={`commentaryLawTocDrawer_${id}_button`}
          >
            {label}
          </ListLinkItem>
        }
        label={label}
        lawId={lawId}
        publicationIdentifiers={publicationId ? [publicationId] : undefined}
      />
    );
  }

  if (type === 'navigation') {
    const { documentKey, subId, isInDevelopment } = item;

    if (isInDevelopment) {
      return <ListItem prefixAdornment={<IconComponent />}>{label}</ListItem>;
    }

    return (
      <PnNavigationDrawerToggle
        key={`link-navigation-drawer-${id}`}
        button={
          <ListLinkItem
            prefixAdornment={<IconComponent />}
            testId={`navigationDrawer_${id}_button`}
          >
            {label}
          </ListLinkItem>
        }
        documentKey={documentKey}
        id={subId}
        label={label}
        type="subId"
      />
    );
  }

  const { to } = item;

  return (
    <ListLinkItem
      key={id}
      linkTo={to}
      prefixAdornment={<IconComponent />}
      testId={`blockLink_${id}_button`}
    >
      {label}
    </ListLinkItem>
  );
};
