import { Loader } from '@elseu/sdu-titan';
import { memo } from 'react';
import styled, { css } from 'styled-components';

interface IPageLoader {
  hasBackground?: boolean;
  testId?: string;
}

const LoaderContainer = styled.div<IPageLoader>`
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  ${({ hasBackground, theme }) =>
    hasBackground &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      height: 100%;
      transform: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${theme.components.navigation.backgroundColor};
      z-index: 2147483649; // higher than cookie bar
    `}
`;

const PageLoaderComponent = ({ hasBackground = false, testId = 'pageLoader' }: IPageLoader) => {
  return (
    <LoaderContainer data-test-id={testId} hasBackground={hasBackground}>
      <Loader height={80} variant="spinner" />
    </LoaderContainer>
  );
};

const PageLoader = memo(PageLoaderComponent);

export { PageLoader };
