import { useMemo } from 'react';

import type { PnNavigationDrawerQuery } from '../../../../generated/content/graphql';
import { useContentRenderingUrls } from '../../../../hooks/useContentRenderingUrls';
import { createLink } from '../../../BlockWithLinks/helpers/createLink';
import { getNavigationDrawerChildren } from '../helpers/getNavigationDrawerChildren';

interface UsePnNavigationLinksProps {
  data?: PnNavigationDrawerQuery;
  documentKey: string;
  slug: string;
}

export const usePnNavigationLinks = ({ data, documentKey, slug }: UsePnNavigationLinksProps) => {
  const { contentUrl, pnNavigationUrl } = useContentRenderingUrls();

  const links = useMemo(
    () =>
      getNavigationDrawerChildren(data).map(
        createLink({ contentUrl, pnNavigationUrl, slug, documentKey }),
      ),
    [data, documentKey, pnNavigationUrl, slug, contentUrl],
  );

  return links;
};
