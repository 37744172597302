import type { BlockLinkProps } from '@elseu/sdu-titan-content-rendering';
import { BlockWithLinks, BlockWithPnNavigationLinks } from '@elseu/sdu-titan-content-rendering';

import type { Urls } from '../../context/SiteConfigProvider';
import { useSiteConfig } from '../../context/SiteConfigProvider';
import { getBlockLinkBaseConfiguration } from '../../helpers/getBlockLinkBaseConfiguration';
import { getMenuPageLinksByTypename } from '../../helpers/getMenuPageLinksByTypename';
import type { MenuPageColumnWithChildren } from './types';

interface MenuPageItemProps {
  item: MenuPageColumnWithChildren;
  index: number;
  slug: string;
}

const convertMenuPageBlockToBlockLink = (
  item: NonNullable<MenuPageColumnWithChildren['children']>[number],
  urls: Urls,
): BlockLinkProps => {
  if (item.__typename === 'MenuPageBlockCLNLink') {
    const { publicationIdentifier, commentaryLawNavigationId } = item;

    return {
      ...getBlockLinkBaseConfiguration(item),
      type: 'commentary',
      publicationId: publicationIdentifier || undefined,
      lawId: commentaryLawNavigationId,
    };
  }

  return {
    ...getBlockLinkBaseConfiguration(item),
    type: 'link',
    to: getMenuPageLinksByTypename(item, urls),
  };
};

const MenuPageItem = ({ item, slug, index }: MenuPageItemProps) => {
  const { urls } = useSiteConfig();
  const { type, id, variant, title, children, navigationId } = item;

  const isPrimary = variant === 'primary';
  const testId = isPrimary ? `menuPageBlockPrimary${index}` : `menuPageBlock${index}`;

  const sharedBlockProps = {
    key: id,
    isPrimary,
    title,
    testId,
  };

  if (type === 'navigation') {
    return navigationId ? (
      <BlockWithPnNavigationLinks {...sharedBlockProps} documentKey={navigationId} slug={slug} />
    ) : null;
  }

  const links = children.map((item) => convertMenuPageBlockToBlockLink(item, urls));

  return <BlockWithLinks {...sharedBlockProps} links={links} />;
};

export { MenuPageItem };
