import { List } from '@elseu/sdu-titan';
import type * as React from 'react';

import type { BlockLinkProps } from './BlockLink';
import { BlockLink } from './BlockLink';
import type { BlockWrapperProps } from './BlockWrapper';
import { BlockWrapper } from './BlockWrapper';

export interface BlockWithLinksProps extends BlockWrapperProps {
  /** Links of the card */
  links: BlockLinkProps[];
  /** Custom List component */
  listComponent?: React.FC<React.PropsWithChildren>;
  /** Render in the primary-style */
  isPrimary?: boolean;
}

export const BlockWithLinks = ({
  links,
  listComponent,
  isPrimary,
  ...blockWrapperProps
}: BlockWithLinksProps) => {
  const ListComponent = listComponent || List;

  return (
    <BlockWrapper isPrimary={isPrimary} {...blockWrapperProps}>
      <ListComponent>
        {links.map((item) => (
          <BlockLink key={item.id} {...item} />
        ))}
      </ListComponent>
    </BlockWrapper>
  );
};
