import type { BlockProps, HeadingProps, SpaceProp } from '@elseu/sdu-titan';
import { Block } from '@elseu/sdu-titan';
import type * as React from 'react';

export interface BlockWrapperProps {
  /** Optional title of the block */
  title?: string;
  /** Heading element */
  heading?: HeadingProps['heading'];
  /** Heading as element */
  renderAs?: HeadingProps['renderAs'];
  /** Background color override */
  backgroundColor?: BlockProps['backgroundColor'];
  /** If marked as primary, default padding / background color will be different */
  isPrimary?: boolean;
  /** Has padding on block */
  padding?: SpaceProp;
  /** Spacing after */
  spaceAfter?: BlockProps['spaceAfter'];
  /** Spacing Title */
  spaceAfterTitle?: HeadingProps['spaceAfter'];
  className?: string;
  testId?: string;
}

export const BlockWrapper: React.FC<React.PropsWithChildren<BlockWrapperProps>> = ({
  backgroundColor,
  title,
  heading = 'h3',
  renderAs = 'h2',
  spaceAfter = { xs: 8, s: 12 },
  padding,
  spaceAfterTitle = 4,
  className,
  children,
  testId,
  isPrimary,
}) => {
  const defaultPadding = isPrimary ? 6 : 0;
  const defaultBackgroundColor = isPrimary ? 'grey10' : 'grey0';

  return (
    <Block
      backgroundColor={backgroundColor ?? defaultBackgroundColor}
      className={className}
      px={padding ?? defaultPadding}
      py={padding ?? defaultPadding}
      spaceAfter={spaceAfter}
      spaceAfterTitle={spaceAfterTitle}
      testId={testId}
      title={title}
      titleAs={renderAs}
      titleSize={heading}
    >
      {children}
    </Block>
  );
};
